<bc-menu class="menu-app-showcase" hideProfileInSubmenu="true" state="close">
  <bc-menu-drawer>
    <bc-menu-item
      path="/dashboard"
      identifierContent="id1"
      text="Inicio"
      iconName="home"
    ></bc-menu-item>
    <bc-menu-item
      path="#"
      identifierContent="id0"
      text="Administración"
      iconName="shield-user"
    ></bc-menu-item>
    <bc-menu-item
      path="#"
      identifierContent="id2"
      text="Información"
      iconName="info"
    ></bc-menu-item>
    <bc-menu-item
      path="/booking"
      identifierContent="id3"
      text="Agendar Ambiente"
      iconName="month"
    ></bc-menu-item>
    <bc-menu-item
      path="/window"
      identifierContent="id4"
      text="Solicitar Ventana"
      iconName="calendar-exclamation"
    ></bc-menu-item>
    <bc-menu-item
      path="#"
      identifierContent="id5"
      text="Autogestión"
      iconName="add-to-car"
    ></bc-menu-item>
    <bc-menu-item
      path="/redirect?url={{
        grafanaURL
      }}/d/8z-nQICnk/adm-menu-cambios-en-componentes-y-parametros?orgId=1"
      identifierContent="id6"
      text="Componentes"
      iconName="components"
    ></bc-menu-item>
    <bc-menu-item
      path="/incident-management"
      identifierContent="id8"
      text="Gestión de Incidentes"
      iconName="hand-holding-document"
    ></bc-menu-item>
    <bc-menu-item
      path="#"
      identifierContent="id7"
      text="TDM"
      iconName="id-number"
    ></bc-menu-item>
    <bc-menu-item
      path="/redirect?url={{ teamsURL }}/conversations?groupId={{
        groupId
      }}&tenantId=b5e244bd-c492-495b-8b10-61bfd453e423"
      identifierContent="id9"
      text="Ayuda"
      iconName="assistant"
    ></bc-menu-item>
  </bc-menu-drawer>
  <bc-menu-main>
    <!-- Modulo de información -->
    <bc-menu-body identifier="id2">
      <bc-menu-column>
        <bc-menu-section title="Acerca de:">
          <bc-menu-option
            *ngFor="let category of categories"
            text="{{ category.category }}"
            href="/informative?category={{ category.category }}&subCategory={{
              category.url
            }}"
          ></bc-menu-option>
        </bc-menu-section>
      </bc-menu-column>
    </bc-menu-body>
    <!-- Modulo de TDM -->
    <bc-menu-body identifier="id7">
      <bc-menu-column>
        <bc-menu-section title="Solicitud de datos:">
          <bc-menu-option
            text="Consultar solicitud de datos"
            href="/tdm/search-order"
          ></bc-menu-option>
        </bc-menu-section>
        <bc-menu-section title="Estado de las vistas:">
          <bc-menu-option
            text="Información sobre las vistas"
            href="{{ azureURL }}/{{ viewsTdmURL }}"
          ></bc-menu-option>
        </bc-menu-section>
      </bc-menu-column>
    </bc-menu-body>
    <!-- Modulo de Autogestion -->
    <bc-menu-body identifier="id5">
      <bc-menu-column>
        <bc-menu-section title="Reinicio de servidores:">
          <bc-menu-option
            text="Crear solicitud de reinicio"
            href="/self-management/server-restart"
          ></bc-menu-option>
        </bc-menu-section>
        <bc-menu-section title="Activación de trace:">
          <bc-menu-option
            text="Crear solicitud de activación"
            href="/self-management/trace-activation"
          ></bc-menu-option>
        </bc-menu-section>
      </bc-menu-column>
    </bc-menu-body>
    <!-- Modulo de Admin -->
    <bc-menu-body identifier="id0">
      <bc-menu-column *ngIf="userAdmin">
        <bc-menu-section title="Aplicativos:">
          <bc-menu-option
            text="Buscar aplicativos"
            href="/admin/app-comp/list-applicative"
          ></bc-menu-option>
          <bc-menu-option
            text="Crear aplicativo"
            href="/admin/app-comp/create-applicative"
          ></bc-menu-option>
        </bc-menu-section>
        <bc-menu-section title="Componentes:">
          <bc-menu-option
            text="Asociar componentes"
            href="/admin/app-comp/link-applicative"
          ></bc-menu-option>
          <bc-menu-option
            text="Buscar componentes"
            href="/admin/app-comp/list-component"
          ></bc-menu-option>
          <bc-menu-option
            text="Crear componente"
            href="/admin/app-comp/create-component"
          ></bc-menu-option>
        </bc-menu-section>
        <bc-menu-section title="Cuestionario:">
          <bc-menu-option
            text="Gestionar Cuestionario"
            href="/admin/question/list"
          ></bc-menu-option>
        </bc-menu-section>
        <bc-menu-section title="Ventanas:">
          <bc-menu-option
            text="Tipos de Ventanas"
            href="/admin/window-type/list"
          ></bc-menu-option>
          <bc-menu-option
            text="Notificaciones de ventanas"
            href="/admin/notifications/list-notifications"
          ></bc-menu-option>
        </bc-menu-section>
      </bc-menu-column>
      <bc-menu-column *ngIf="userTdm">
        <bc-menu-section title="TDM:">
          <bc-menu-option
            text="Listado de productos"
            href="/admin/tdm/list-products"
          ></bc-menu-option>
          <bc-menu-option
            text="Crear producto"
            href="/admin/tdm/new-product"
          ></bc-menu-option>
          <bc-menu-option
            text="Solicitudes de datos"
            href="/admin/tdm/list-orders"
          ></bc-menu-option>
        </bc-menu-section>
      </bc-menu-column>
    </bc-menu-body>
    <!-- Fin Modulo de información -->
  </bc-menu-main>
  <div class="bc-content-body">
    <router-outlet></router-outlet>
    <app-main-footer></app-main-footer>
  </div>
</bc-menu>
