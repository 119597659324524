export const environment = {
  production: false,
  azureConfig: {
    redirectUri: 'https://emma-adm-ambientes-dev.apps.ambientesbc.com',
    /*
    redirectUri: 'https://adm-ambientes-dev.apps.ambientesbc.com/',
    clientId: '344d8c7e-19e6-4d32-9670-fb162e83e7d5',*/
    tenantId: '428f4e2e-13bf-4884-b364-02ef9af41a1d',
    clientId: 'b146c3df-da7e-4fa4-9e5d-1f04648f794a',
    loginMicrosoft: 'https://login.microsoftonline.com',
    appScope: `api://b146c3df-da7e-4fa4-9e5d-1f04648f794a/default`,
    sendTokenTo: {
      'https://ingenieriati-ext-dev.apps.ambientesbc.com': [
        `api://b146c3df-da7e-4fa4-9e5d-1f04648f794a/default`,
      ],
    },
  },
  config: {
    endpoints: {
      BOK: 'https://ingenieriati-ext-dev.apps.ambientesbc.com/adm-ambientes/apibooking',
      SFM: 'https://ingenieriati-ext-dev.apps.ambientesbc.com/adm-ambientes/apiselfmanagement',
      ADA: 'https://ingenieriati-ext-dev.apps.ambientesbc.com/adm-ambientes',
      CONCURRENCY:
        'https://prod-21.westus.logic.azure.com:443/workflows/349ab582bab24a5abd45c59036359fd2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=aOsvcwOYGQtjRftjo3IWwp7J5LfOYxWgTspu34ZmaGc',
      NOTIFICATIONS:
        'https://prod-150.westus.logic.azure.com:443/workflows/4ffc47ffd77244018a8c38e3693bdc8d/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=vUDn1TrcXsy9VEXw2C-nmk7m06YSHoP3wgG5C5uA6aI',
    },
  },
};
